.about {
  background: linear-gradient(180deg, #353535 0%, var(--background));
}

.about_container {
  max-width: 120rem;
  margin: 0 auto;
  padding: 6.4rem;
}

.about_text_container {
  width: 100%;
  color: white;
  font-size: 1.8rem;
}
.about_title {
  font-size: 5.2rem;
  font-weight: 600;
  margin-bottom: 0.8rem;
}
.about_subtitle {
  font-size: 4.4rem;
  font-weight: 500;
  margin-bottom: 2.4rem;
  color: var(--primary);
}

.about_text {
  font-size: 2rem;
  line-height: 1.6;
  list-style: none;
}
.about_text_list_item span,
.about_text_list_item blockquote {
  display: block;
  margin-left: 4.2rem;
  margin-bottom: 1.2rem;
  position: relative;
}
.about_text_list_item svg {
  position: absolute;
}

.about_image_container {
  float: right;
  width: 36.9%;
  margin: 0 0 3.2rem 4.8rem;
  border-radius: 4px;
  overflow: hidden;
}
.about_image {
  display: block;
  object-fit: cover;
  width: 100%;
  height: 100%;
}

/**************************/
/* BELOW 768px (Tablet) */
/**************************/

/* 768 / 16 = 48 */
@media (max-width: 48em) {
  html {
    /* font-size: 9px; */
    /* 9px / 16px = 0.5625 = 56.25% */
    /* Percentage of user's browser font-size setting */
    font-size: 56.25%;
  }

  .about_container {
    padding: 4.8rem;
  }

  .about_title {
    font-size: 5.2rem;
  }
  .about_subtitle {
    font-size: 3.6rem;
  }
}

/**************************/
/* BELOW 640px (Tablet) */
/**************************/

/* 640 / 16 = 40 */
@media (max-width: 40em) {
  .about_container {
    padding: 3.2rem;
  }

  .about_title {
    margin-bottom: 0.4rem;
  }
  .about_subtitle {
    margin-bottom: 1.6rem;
  }
  .about_text {
    line-height: 1.4;
  }
}

/**************************/
/* BELOW 560px (Tablet) */
/**************************/

/* 560 / 16 = 35 */
@media (max-width: 35em) {
  .about_container {
    padding: 3.2rem 4.8rem;
  }

  .about_text_container {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .about_image_container {
    display: none;
  }

  .about_title {
    text-align: center;
  }
  .about_subtitle {
    text-align: center;
  }
}

/**************************/
/* BELOW 432px (Mobile) */
/**************************/

/* 432 / 16 = 27 */
@media (max-width: 27em) {
  html {
    /* font-size: 8px; */
    /* 8px / 16px = 0.5 = 50% */
    /* Percentage of user's browser font-size setting */
    font-size: 50%;
  }

  .about_container {
    padding: 3.2rem;
  }
}
