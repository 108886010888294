.modal_btn {
  font-family: inherit;
  font-size: 2rem;
  font-weight: 500;
  letter-spacing: 1px;
  color: white;
  background-color: var(--primary);
  border: none;
  border-radius: 4px;
  margin-top: 3.2rem;
  padding: 1.6rem 2.4rem;
  cursor: pointer;
}
.modal_btn:hover {
  background-color: var(--hover);
  transition: all 0.2s ease-out;
}

.A_modal_root {
  width: 100vw;
  height: 100vh;
}

.A_modal_overlay {
  width: 100%;
  height: 100%;
}

.A_modal_container {
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.A_modal {
  max-width: calc(120rem - 12.8rem);
  width: 100%;
  height: 72vh;
  margin: 0;
  margin-top: 5.6rem;
  padding: 3.2rem;
  border-radius: 4px;
  overflow-y: hidden;

  /* react responsive modal animation is buggy blinking after close, to fix that these overrides are used */
  animation-fill-mode: forwards !important;
  animation-duration: 0.5s !important;
}

.A_modal_close_button {
  width: 3rem;
  height: 3rem;
  top: 1.6rem;
  right: 1.6rem;
}
.A_modal_close_icon,
.A_modal_close_icon:link,
.A_modal_close_icon:visited {
  width: 100%;
  height: 100%;
  fill: white;
  background-color: var(--background);
  border-radius: 50%;
}
.A_modal_close_icon:hover,
.A_modal_close_icon:active {
  background-color: var(--hover);
}

.panel_title {
  font-size: 2.4rem;
  font-weight: 600;
  text-align: center;
  margin: 0 auto 3.2rem auto;
  padding: 0 0 1.6rem 0;
  color: var(--background);
  border-bottom: 2px solid var(--background);
}

/* Menu + Content */
.panel_menu_content_container {
  display: flex;
  gap: 2.4rem;
  height: calc(72vh - (3.2rem * 4.75));
}

.panel_menu {
  list-style: none;
  width: 30%;
  color: var(--background);
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  overflow-y: auto;
}
.panel_item {
  color: var(--background);
}

.panel_link,
.panel_link:link,
.panel_link:visited {
  font-size: 1.8rem;
  font-weight: 700;
  line-height: 1.1;
  cursor: pointer;
  display: inline-block;
}
.panel_link:hover,
.panel_link:active {
  color: var(--hover);
  transition: all 0.2s ease-out;
}
.panel_link_selected {
  font-size: 1.8rem;
  font-weight: 700;
  line-height: 1.1;
  cursor: pointer;
  display: inline-block;
  color: var(--hover);
}

.panel_content {
  width: 70%;
  flex: 1 1 auto;
  overflow-y: auto;
}

/**************************/
/* BELOW 1200px */
/**************************/

@media (max-width: 75em) {
  .A_modal {
    margin-left: 6.4rem;
    margin-right: 6.4rem;
  }
}

/**************************/
/* BELOW 768px (Tablet) */
/**************************/

/* 768 / 16 = 48 */
@media (max-width: 48em) {
  html {
    /* font-size: 9px; */
    /* 9px / 16px = 0.5625 = 56.25% */
    /* Percentage of user's browser font-size setting */
    font-size: 56.25%;
  }

  .A_modal {
    margin-left: 3.2rem;
    margin-right: 3.2rem;
    height: 75vh;
  }

  .panel_menu_content_container {
    height: calc(75vh - (3.2rem * 4.75));
  }
}

/**************************/
/* BELOW 640px (Tablet) */
/**************************/

/* 640 / 16 = 40 */
@media (max-width: 40em) {
  .A_modal {
    height: 82vh;
  }

  .panel_menu_content_container {
    height: calc(82vh - (3.2rem * 4.75));
  }
}

/**************************/
/* BELOW 560px (Tablet) */
/**************************/

/* 560 / 16 = 35 */
@media (max-width: 35em) {
  .modal_btn {
    margin-top: 2.4rem;
  }

  .A_modal {
    margin: 5.39rem 3.2rem;
    height: auto;
    background-color: white;
  }

  .panel_title {
    margin-bottom: 1.8rem;
  }

  .panel_menu_content_container {
    flex-direction: column;
    gap: 3.2rem;
    height: auto;
  }

  .panel_menu {
    width: 100%;
    height: auto;
    text-align: center;
    flex: 0 0 auto;
    overflow-y: auto;
    row-gap: 1.8rem;
    padding-bottom: 1.8rem;
    border-bottom: 2px solid var(--background);
  }

  .panel_content {
    width: 100%;
    height: auto;
  }
}

/**************************/
/* BELOW 432px (Mobile) */
/**************************/

/* 432 / 16 = 27 */
@media (max-width: 27em) {
  .A_modal {
    margin: 6.2rem 3.2rem;
  }

  .modal_btn {
    margin-top: 2rem;
  }
}
