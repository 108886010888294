.main {
  max-width: 100%;
  background-color: white;
}

.main_content {
  max-width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.main_text_container {
  width: 50%;
  display: flex;
  justify-content: flex-end;
  align-self: stretch;
}
.main_texts {
  /* 64 - 3.2 - 3.2 = 53.6 */
  max-width: 53.6rem;
  margin: 4.8rem 3.2rem;
  flex-grow: 1;
}
.main_title,
.points_container_title {
  font-size: 3.6rem;
  font-weight: 600;
  margin-bottom: 2.4rem;
  color: var(--primary);
}
.points_container_title_pop {
  font-size: 3.6rem;
  font-weight: 600;
  margin-bottom: 2.4rem;
  color: var(--primary);
  text-align: center;
  /* text-shadow: 0 0 1.8rem rgba(0, 0, 0, 0.25); */
}
.main_text {
  font-size: 2rem;
  line-height: 1.4;
  margin-bottom: 1.6rem;
}
.main_text:last-of-type {
  margin-bottom: 0;
}

.main_image_container {
  width: 50%;
  align-self: stretch;
  background-image: url("/public/images/main_image_container.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: flex-start;
}
.points_container {
  /* 64 - 3.2 - 3.2 = 53.6 */
  max-width: 53.6rem;
  margin: 4.8rem 3.2rem;
  flex-grow: 1;
}
.points_text {
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
}
.points_text_list_item {
  font-size: 2.4rem;
  font-weight: 600;
  color: var(--background);
  display: flex;
  align-items: flex-start;
  gap: 1.2rem;
  cursor: pointer;
}
.points_text_list_item svg {
  flex-shrink: 0;
}
.points_text_list_item span {
  background-color: rgba(255, 255, 255, 0.75);
  border-radius: 4px;
  flex-grow: 1;
  padding: 1.6rem;
}
.points_text_list_item span:hover {
  background-color: rgba(255, 255, 255, 0.9);
  transition: all 0.2s ease-out;
}
.points_text_list_item svg:hover {
  background-color: rgba(204, 71, 164, 0.85) !important;
  transition: all 0.2s ease-out;
}

/**************************/
/* BELOW 640px (Tablet) */
/**************************/

/* 640 / 16 = 40 */
@media (max-width: 40em) {
  .main_content {
    flex-direction: column;
  }

  .main_text_container {
    width: 100%;
    justify-content: center;
  }
  .main_texts {
    /* 64 - 3.2 - 3.2 = 53.6 */
    max-width: 100%;
    margin: 3.2rem 4.8rem;
    display: flex;
    flex-direction: column;
  }
  .main_title,
  .points_container_title {
    text-align: center;
    margin-bottom: 1.6rem;
  }
  .main_text {
    margin-bottom: 1.6rem;
  }
  .main_btn {
    margin: 2.4rem auto 0 auto;
  }

  .main_image_container {
    width: 100%;
    justify-content: center;
  }

  .points_container {
    /* 64 - 3.2 - 3.2 = 53.6 */
    max-width: 100%;
    margin: 3.2rem 4.8rem 4.8rem 4.8rem;
  }
}

/**************************/
/* BELOW 560px (Tablet) */
/**************************/

/* 560 / 16 = 35 */
@media (max-width: 35em) {
  .points_container {
    margin: 3.2rem 4.8rem 4.8rem 4.8rem;
  }
}

/**************************/
/* BELOW 432px (Mobile) */
/**************************/

/* 432 / 16 = 27 */
@media (max-width: 27em) {
  .main_texts {
    margin: 3.2rem;
  }

  .points_container {
    margin: 3.2rem 3.2rem 4.8rem 3.2rem;
  }
}
