.navbar {
  position: sticky;
  top: 0;
  z-index: 999;
  background-color: white;
}

.navbar_mob_container {
  max-width: 120rem;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
}
.navbar_mob,
.navbar_mob:link,
.navbar_mob:visited {
  padding: 1.2rem 0;
  font-size: 3.2rem;
  font-weight: 600;
  color: var(--background);
  display: inline-flex;
  align-items: center;
  text-decoration: none;
  cursor: pointer;
  transition: all 0.3s;
}
.navbar_mob:hover,
.navbar_mob:active {
  color: var(--hover);
}

.navbar_line_container {
  background-color: white;
  display: flex;
  justify-content: center;
}
.navbar_line {
  max-width: 96rem;
  height: 0.8rem;
  margin: 0 20%;
  flex-grow: 1;
  background-color: var(--primary);
}

.navbar_menu_container {
  background: linear-gradient(180deg, #353535 0%, var(--background));
}
.nav_menu {
  max-width: 120rem;
  margin: 0 auto;
  padding: 0 3.2rem;
  height: 5.6rem;
  display: flex;
  justify-content: center;
  gap: 3.2rem;
  list-style: none;
  color: white;
  font-size: 2.4rem;
  font-weight: 300;
}

.nav_item {
  height: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.nav_link {
  text-decoration: none;
  color: white;
  height: 100%;
  display: flex;
  align-items: center;
}
.nav_link:hover {
  border-bottom: 0.8rem solid var(--primary);
  transition: all 0.2s ease-out;
}
.nav_link.active {
  font-weight: 600;
  height: 100%;
  display: flex;
  align-items: center;
  border-bottom: 0.8rem solid var(--primary);
  transition: all 0.2s ease-out;
}
.nav_link.active:hover {
  border-bottom: 0.8rem solid var(--primary);
}

/**************************/
/* BELOW 640px (Tablet) */
/**************************/

/* 640 / 16 = 40 */
@media (max-width: 40em) {
  .navbar_line {
    margin: 0 15%;
  }
}

/**************************/
/* BELOW 432px (Mobile) */
/**************************/

/* 432 / 16 = 40 */
@media (max-width: 27em) {
  .navbar_mob_container {
    height: 6.2rem;
  }

  .nav_menu {
    padding: 0 15%;
    height: auto;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 5.2rem 5.2rem;
    column-gap: 0;
    row-gap: 0;
    justify-items: center;
    align-items: center;
  }
}
