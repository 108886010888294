/* 
https://stackoverflow.com/questions/66356329/how-to-add-styling-for-elements-in-react-markdown
*/

.reactMarkDown * {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-size: 1.6rem;
  line-height: 1.4;
  text-align: left !important;
}

.reactMarkDown table,
.reactMarkDown td,
.reactMarkDown th {
  border: none !important;
  padding: 0.4rem 0.8rem !important;
  vertical-align: top;
}

.reactMarkDown table {
  width: 100%;
  border-collapse: collapse;
}
.reactMarkDown thead th {
  padding: 0 0.8rem 0.4rem 0.8rem !important;
  border-bottom: 1px solid var(--background) !important;
}

.reactMarkDown tr:nth-child(odd) td {
  background-color: var(--tint);
}

/* test on first table column width */
.reactMarkDown table tr td:nth-child(1) {
  white-space: nowrap;
}

.reactMarkDown a,
.reactMarkDown a:link,
.reactMarkDown a:visited {
  color: var(--background);
}
.reactMarkDown a:hover,
.reactMarkDown a:active {
  color: var(--hover);
}

.reactMarkDown p {
  margin-bottom: 1.2rem;
}

.reactMarkDown img {
  display: block;
  width: 50%;
  margin: 1.2rem auto;
}

.reactMarkDown ul {
  margin: 0;
  padding: 0 0 0 1.8rem;
  list-style: none;
}
.reactMarkDown ul li {
  margin-top: 0.4rem;
}
.reactMarkDown ul li::before {
  content: "\2022";
  color: var(--primary);
  font-weight: 900;
  display: inline-block;
  width: 1.8rem;
  margin-left: -1.8rem;
}

.reactMarkDown ol {
  margin: 0;
  padding: 0 0 0 1.8rem;
}

.reactMarkDown h2 {
  font-size: 2.4rem;
  padding-top: 0.4rem;
  padding-bottom: 0.8rem;
}

.reactMarkDown h3 {
  font-size: 2rem;
  padding-top: 0.2rem;
  padding-bottom: 0.4rem;
}

/**************************/
/* BELOW 1024px (Laptop) */
/**************************/

/* 1024 / 16 = 64 */
@media (max-width: 64em) {
  .reactMarkDown table tr td:nth-child(1) {
    white-space: normal;
  }
}
