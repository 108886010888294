:root {
  /* https://maketintsandshades.com/#ff59cd */
  /* --primary: rgb(255, 122, 215); */
  --source: #ff59cd;
  --primary: #ff7ad7; /* third tint of #ff59cd */
  --background: #444;
  --hover: #cc47a4; /* third shade of #ff59cd */
  --tint: #ffeefa; /* last tint of #ff59cd */
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  /* font-size: 10px; */
  /* 10px / 16px = 0.625 = 62.5% */
  /* Percentage of user's browser font-size setting */
  font-size: 62.5%;
  /* height: 100%; */
}

body {
  /* font-family: "Rubik", sans-serif; */
  /* font-family: 'Open Sans', sans-serif; */
  /* font-family: 'Noto Sans', sans-serif; */
  /* font-family: 'Inter', sans-serif; */
  /* font-family: 'Nunito', sans-serif; */
  /* font-family: 'Work Sans', sans-serif; */
  /* font-family: 'Quicksand', sans-serif; */
  /* font-family: 'Signika Negative', sans-serif; */
  /* font-family: 'Merriweather Sans', sans-serif; */
  /* font-family: 'Alegreya Sans', sans-serif; */
  /* font-family: 'Fira Sans', sans-serif; */
  /* font-family: 'Source Sans Pro', sans-serif; */
  /* font-family: 'Libre Franklin', sans-serif; */
  /* font-family: 'Lato', sans-serif; */
  font-family: "Roboto", sans-serif;
  line-height: 1;
  font-weight: 400;
  color: var(--background);
  /* height: 100%; */
}

.container {
  margin: 0 auto;
  /* height: 100%; */
}

.about_title.animate__animated.animate__fadeInLeft {
  animation-duration: 1s;
}
.about_subtitle.animate__animated.animate__fadeInLeft {
  animation-duration: 1.25s;
}
.about_text.animate__animated.animate__fadeInLeft {
  animation-duration: 1.5s;
}

.panel_btn.animate__animated.animate__fadeInLeft {
  animation-duration: 1.5s;
}

.about_title.animate__animated.animate__fadeInRight {
  animation-duration: 1s;
}

.hero_title.animate__animated.animate__fadeInDown {
  animation-duration: 1s;
}
.hero_subtitle.animate__animated.animate__fadeInDown {
  animation-duration: 1.25s;
}
.hero_about.animate__animated.animate__fadeInDown {
  animation-duration: 1.5s;
}

.hero_line.animate__animated.animate__fadeIn {
  animation-duration: 2s;
}

/*
--- 01 TYPOGRAPHY SYSTEM

- Font sizes (px)
10 / 12 / 14 / 16 / 18 / 20 / 24 / 30 / 36 / 44 / 52 / 62 / 74 / 86 / 98

- Font weights
Light: 300
Default: 400
Medium: 500
Bold: 600
(Extra-Bold: 700)

- Line heights
Default: 1
(Small: 1.05)
(Medium: 1.2)
(Main) 1.4
Paragraph default: 1.6

- Letter spacing
1px
4px

1rem
0.75rem
0.25rem


--- 02 COLORS

- Primary: 
#ff59cd // source

#ff7ad7 // primary
#444 // background
#cc47a4 // hover
#ffeefa // tint

white


--- 03 BORDER-RADIUS

Default: 4px

--- 04 WHITESPACE

- Spacing system (px)
2 / 4 / 8 / 12 / 16 / 24 / 32 / 48 / 64 / 80 / 96 / 128
*/
