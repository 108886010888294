.hero {
  background-image: url("/public/images/background.jpg");
  max-width: 100%;
  height: 700px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: flex-end;
}

.hero_container {
  max-width: 120rem;
  margin: 0 auto 9.6rem auto;
  padding: 0 3.2rem;
  color: white;
  text-align: center;
  flex-grow: 1;
}

.hero_title {
  font-size: 8.6rem;
  font-weight: 500;
  letter-spacing: 1rem;
  padding-bottom: 1.6rem;
}
.hero_subtitle {
  font-size: 4.4rem;
  font-weight: 400;
  letter-spacing: 0.75rem;
  padding-bottom: 1.6rem;
}
.hero_about {
  font-size: 3.6rem;
  font-weight: 300;
  letter-spacing: 0.25rem;
  padding: 1.6rem 0 0 0;
}

.hero_line {
  margin: 0 20%;
  height: 0.4rem;
  background-color: var(--primary);
}

/**************************/
/* BELOW 768px (Tablet) */
/**************************/

/* 768 / 16 = 48 */
@media (max-width: 48em) {
  .hero {
    height: 650px;
  }
}

/**************************/
/* BELOW 640px (Tablet) */
/**************************/

/* 640 / 16 = 40 */
@media (max-width: 40em) {
  .hero {
    height: 600px;
  }

  .hero_title {
    font-size: 7.4rem;
    padding-bottom: 1.6rem;
  }
  .hero_subtitle {
    font-size: 3.6rem;
    padding-bottom: 1.6rem;
  }
  .hero_about {
    font-size: 3rem;
    padding: 1.6rem 0 0 0;
  }
}

/**************************/
/* BELOW 432px (Mobile) */
/**************************/

/* 432 / 16 = 40 */
@media (max-width: 27em) {
  html {
    /* font-size: 8px; */
    /* 8px / 16px = 0.5 = 50% */
    /* Percentage of user's browser font-size setting */
    font-size: 50%;
  }

  .hero {
    height: 500px;
  }

  .hero_title {
    font-size: 6.2rem;
    letter-spacing: 0.5rem;
    padding-bottom: 1.6rem;
  }
  .hero_subtitle {
    font-size: 3rem;
    letter-spacing: 0.5rem;
    padding-bottom: 1.6rem;
  }
  .hero_about {
    font-size: 2.4rem;
    letter-spacing: 0.05rem;
    padding: 1.6rem 0 0 0;
  }

  .hero_line {
    margin: 0 15%;
  }
}
