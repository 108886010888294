.contacts {
  background-color: var(--background);
  background: linear-gradient(180deg, #353535 0%, var(--background));
  max-width: 100%;
}

.contacts_container {
  max-width: 120rem;
  margin: 0 auto;
  padding: 4.8rem 3.2rem 6.4rem 3.2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3.2rem;
  text-align: center;
  color: white;
}

.contacts_title {
  font-size: 3.6rem;
  font-weight: 500;
  letter-spacing: 4px;
}

.contacts_subtitle {
  font-size: 2rem;
  font-weight: 300;
  margin-bottom: 0.4rem;
}

.contacts_mob_email {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.8rem;
}

.mob,
.mob:link,
.mob:visited {
  font-size: 3rem;
  font-weight: 500;
  color: white;
  text-decoration: none;
  cursor: pointer;
  transition: all 0.3s;
  display: inline-flex;
  align-items: center;
}
.mob:hover,
.mob:active {
  color: var(--source);
}

.email {
  font-size: 1.6rem;
  font-weight: 300;
  display: flex;
  justify-content: center;
  align-items: baseline;
}
.email_link,
.email_link:link,
.email_link:visited {
  font-size: 1.6rem;
  font-weight: 300;
  color: white;
  text-decoration: none;
}
.email_link:hover,
.email_link:active {
  color: var(--source);
  text-decoration: underline;
  transition: all 0.2s ease-out;
}

.contacts_facebook,
.contacts_facebook:link,
.contacts_facebook:visited {
  font-size: 2rem;
  font-weight: 300;
  color: white;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
}
.contacts_facebook:hover,
.contacts_facebook:active {
  color: var(--source);
  text-decoration: underline;
  transition: all 0.2s ease-out;
}
.contacts_facebook:first-child {
  margin-bottom: 0.8rem;
}

/**************************/
/* BELOW 768px (Tablet) */
/**************************/

/* 768 / 16 = 48 */
@media (max-width: 48em) {
  .contacts_container {
    padding: 3.2rem 3.2rem 4.8rem 3.2rem;
  }
}

/**************************/
/* BELOW 432px (Mobile) */
/**************************/

/* 432 / 16 = 27 */
@media (max-width: 27em) {
  .contacts_container {
    gap: 2.4rem;
  }

  .contacts_title {
    font-size: 3rem;

    letter-spacing: 4px;
  }

  .contacts_subtitle {
    font-size: 1.8rem;
    line-height: 1.4;
  }

  .mob {
    font-size: 3rem;
    margin-bottom: 0.8rem;
  }

  .email {
    font-size: 1.4rem;
  }

  .contacts_facebook {
    font-size: 1.8rem;
  }
}
